import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Introducing Dashbot’s NLP Assessment, an easy-to-use tool to identify issues with a model and quickly and seamlessly make improvements, based on Dashbot’s proprietary algorithms.`}</p>
    <p>{`The Natural Language Processing (NLP) model does a lot of the heavy lifting for any conversational interface utilizing AI. It’s the backbone for a lot of the processes that keep the chatbot or voice app running smoothly, so it’s not an understatement to say that the health of the NLP model can make or break a project.`}</p>
    <p>{`Dashbot knows firsthand how important it is to keep the NLP model in tip-top shape, but we also know that the necessary maintenance is no small task. Depending on the number of Intents supported and the message volume, the amount of work to monitor the model can quickly get out of hand and that itself can turn into an obstacle to making optimizations.`}</p>
    <p>{`We learned from our users that they were spending a lot of time manually optimizing their NLP, often taking up a lion’s share of their time and resources. We wanted to make their lives easier. That’s where Dashbot’s NLP Assessment can help.`}</p>
    <h3><strong parentName="h3">{`Improve Intent Accuracy`}</strong></h3>
    <p>{`Our Assessment provides a review of your NLP model, highlighting both the Intents that require better training data and recommended improvements in an easy-to-digest report.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2020/01/15153454/assessment-16-1024x640.png",
        "alt": null
      }}></img>{` After running the Assessment, we’ll provide a list of suggestions for improvements to the NLP model that you can implement with a click of a button. All without leaving Dashbot.`}</p>
    <p>{`The Assessment:`}</p>
    <ul>
      <li parentName="ul">{`Identifies potential Intent collisions with training phrase overlaps – phrases that are too similar to ones in other Intents`}</li>
      <li parentName="ul">{`Identifies training phrases that are too dissimilar`}</li>
      <li parentName="ul">{`Identifies additional training phrases, based on user data, to improve an Intent`}</li>
      <li parentName="ul">{`Identifies additional Intents to add based on user data`}</li>
      <li parentName="ul">{`Provides the ability to accept recommendation and update the NLP model from Dashbot`}</li>
    </ul>
    <p>{`That’s just the start. We developed this tool to improve efficiency and make NLP optimization more approachable, so it can also be used to identify missing common Intents, unused Intents, and Intents not enabled for a webhook, to name a few.`}</p>
    <h3><strong parentName="h3">{`Tune into your users`}</strong></h3>
    <p>{`The best data is produced by users. It’s the most accurate data available and paves the way for long-term success by providing insight to what users are actually saying. The NLP Assessment provides another point-of-view to examine user interactions so you can develop a better user experience.`}</p>
    <p>{`When the Assessment ingests the NLP model, it’s able to make use of user data by pointing out mishandled and unhandled Intents. By looking critically at these interactions, Dashbot can recommend changes to make that will improve response accuracy and reduce fallback error percentage.`}</p>
    <h3><strong parentName="h3">{`Quick and Seamless Integration`}</strong></h3>
    <div className="wp-block-image">
  <figure className="alignleft">
    ![](https://i.dashbot.io/wp-home/uploads/2020/01/13101356/image-2-1.png)
  </figure>
    </div>
Our NLP Assessment is meant to save time, so we wanted our integration to be as
seamless as possible via our custom API. By connecting your Dialogflow account
to Dashbot and running the Assessment, you can quickly review your model for
suggested improvements, make changes, and deploy updates with a few simple
clicks. We also provide the option to upload the model as a file to Dashbot.
    <p>{`Look for the Assessment under the “NLP Optimization” section of your report, and if you have any questions, don’t hesitate to shoot it over to us. We’re here to help you make the most of your data!`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>
    <h2><div aria-hidden="true" className="wp-block-spacer" style={{
        "height": "100px"
      }}></div>{`About Dashbot`}</h2>
    <p><em parentName="p">{`Dashbot is an analytics platform for conversational interfaces that enables enterprises to increase satisfaction, engagement, and conversions through actionable insights and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support DialogFlow, Alexa, Google Assistant, Facebook Messenger, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      